.block-code {
    background-color: #f8f9fa; 
    color: #212529; 
    padding: 15px;
    border-radius: 5px;
    overflow-x: auto;
    font-family: 'Courier New', Courier, monospace; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
}

.block-code code {
    color: inherit; 
    white-space: pre-wrap; 
}