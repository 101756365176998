:root {

  // Body background
  --#{$prefix}body-bg: #FBFAF8; 
  --#{$prefix}body-color: #2D2C2A; // Dark gray for text
  --#{$prefix}headings-color: #4C4B47; // Subtle dark gray for headings
  --#{$prefix}navbar-vertical-bg: #FFFFFF; // Pure white for vertical navbar
  --#{$prefix}navbar-bg: #FFFFFF; // White for horizontal navbar

  // Card background
  --#{$prefix}card-background: #FFFFFF; // White for cards

  // Form inputs
  --#{$prefix}input-bg: #FFFFFF; // White input background
  --#{$prefix}input-disabled-bg: #ECEBE9; // Soft gray for disabled inputs
  --#{$prefix}input-focus-border: rgba(106, 90, 205, 0.5); // Soft blue-purple border on focus
  --#{$prefix}input-border: #E0DED9; // Light gray for input borders
  --#{$prefix}input-placeholder-color: #B9B8B2; // Muted gray for placeholder text
  --#{$prefix}input-focus-box-shadow: 0px 0px 0px 3px rgba(106, 90, 205, 0.2); // Subtle blue shadow on focus

  // Checkbox & Radio inputs
  --#{$prefix}input-check-border: var(--#{$prefix}input-border);

  // Input group addons
  --#{$prefix}input-group-addon-bg: #ECEBE9; // Light gray for addons
  --#{$prefix}form-text-color: #9F9D96; // Muted medium gray for helper text
  --#{$prefix}form-label-color: #6E6C64; // Darker gray for labels

  // Borders
  --#{$prefix}border-color: #E0DED9; // Light gray for general borders

  // Dropdowns
  --#{$prefix}dropdown-background: #FFFFFF; // White for dropdown backgrounds
  --#{$prefix}dropdown-link-color: #2D2C2A; // Dark gray for dropdown text
  --#{$prefix}dropdown-link-hover-color: #4C4B47; // Slightly darker gray for hover state
  --#{$prefix}dropdown-link-hover-bg: #ECEBE9; // Soft gray for hover background
  --#{$prefix}dropdown-border-width: 0px; // No border width for dropdowns
  --#{$prefix}dropdown-shadow: 0 10px 30px 0 rgba(31, 45, 61, 0.1); // Soft shadow for dropdowns

  // Modal background
  --#{$prefix}modal-bg: #F7F6F3; // Same as body background for consistency

  // Progress bar background
  --#{$prefix}progress-background: #E0DED9; // Light gray for progress bar backgrounds

  // Ghost buttons
  --#{$prefix}btn-ghost-bg: #ECEBE9; // Soft gray for ghost buttons

  // Tagify component
  --#{$prefix}tagify-border-color: #E0DED9; // Light gray for tagify component borders

  // Navbar vertical
  --#{$prefix}navbar-vertical-bg: #4C4B47; // Darker gray for vertical navbar
  --#{$prefix}navbar-vertical-link: #9F9D96; // Medium gray for navbar links
  --#{$prefix}navbar-vertical-link-hover: #6E6C64; // Slightly darker gray for hover links

  // Accordion (example)
  // --#{$prefix}accordion-bg: #FFFFFF; // Uncomment if accordion background needed

  // Background for specific sections
  .bg-dark {
    background-color: #2D2C2A; // Very dark gray for dark mode elements
  }
}