.lesson-container {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.lesson-content {
  position: relative;
  z-index: 1;
}

.blurred-content {
  filter: blur(8px);
  pointer-events: none;
  user-select: none;
  opacity: 0.6;
}

.overlay-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #333;
  text-align: center;
  padding: 25px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  width: 100%;
  max-width: 500px;
}

.overlay-content p {
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #444;
}

@media (max-width: 576px) {
  .overlay-content {
    max-width: 90vw;
    padding: 15px;
  }
}

.pricing-button {
  display: inline-block;
  background: #6A5ACD;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease, background 0.3s ease;
  width: 100%;
  max-width: 250px;
  text-align: center;
}

@media (max-width: 576px) {
  .pricing-button {
    font-size: 0.9rem;
    padding: 10px 20px;
    width: 100%;
    max-width: none;
  }
}

.pricing-button:hover {
  background: #5A4CAF;
  transform: translateY(-3px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}