// Course Container

.course-container {
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease-in-out;

  /* Default padding */
  padding-left: 15px;
  padding-right: 15px;

  /* Small devices (up to 576px) */
  @media (max-width: 576px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Medium devices (up to 768px) */
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* Large devices (up to 992px) */
  @media (max-width: 992px) {
    padding-left: 250px;
    padding-right: 250px;
  }

  /* Extra large devices (1200px and above) */
  @media (min-width: 1200px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.collapsed-sidebar .course-container {
  /* Adjust padding when sidebar is collapsed */
  padding-left: 250px;
  padding-right: 250px;

  /* Small devices (up to 576px) */
  @media (max-width: 576px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Medium devices (up to 768px) */
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  /* Large devices (up to 992px) */
  @media (max-width: 992px) {
    padding-left: 250px;
    padding-right: 250px;
  }

  /* Extra large devices (1200px and above) */
  @media (min-width: 1200px) {
    padding-left: 250px;
    padding-right: 250px;
  }
}

// Course sidebar

// .course-sidebar {
//   position: fixed;
//   width: 23.75rem;
//   min-height: 100vh;
//   z-index: 1;
//   top: 3.6rem;
//   border-radius: 0px;
//   @media (max-width: 576px) {
//     top: 1.25rem;
//     width: 100%;
//     position: relative;
//   }
//   @media (min-width: 768px) and (max-width: 991.98px) {
//     top: 1.25rem;
//     width: 100%;
//     position: relative;
//   }
// }
